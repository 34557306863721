/* Keyframes for the intro content (sliding in from the left) */
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Keyframes for the skills content (sliding in from the right) */
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Remove animation by default */
.about-intro-content,
.about-skills {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 0.3s ease;
}

/* When active, apply the animation */
.animate-left {
  animation: slideInLeft 1s ease forwards;
}

.animate-right {
  animation: slideInRight 1s ease forwards;
}

#about {
  padding-top: 5px;
}

.about-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  padding: 5rem 1rem; /* Adjust padding for a balanced layout */
  background-color: #f7f7f7;
  min-height: 100vh; /* Ensures full viewport height for vertical centering */
}

.about-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 80px;
}

.about-intro-content {
  flex: 1;
  margin-right: 2rem;
  min-width: 300px;
  /* Animation for sliding in from the left */
  animation: slideInLeft 1s ease forwards;
}

.about-intro h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.about-intro p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 2rem;
}

.about-intro-content::before {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  background-color: #007bff;
  margin-bottom: 1rem;
}

.about-skills {
  flex: 1;
  min-width: 300px;
  padding: 2rem;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* Animation for sliding in from the right */
  animation: slideInRight 1s ease forwards;
}

.about-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.about-list li {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  color: #333;
}

.about-list li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #0067d6;
  font-size: 1.5rem;
}

.about-list li:hover {
  color: #007bff;
  transform: translateX(5px);
  transition: all 0.3s ease;
}

.contact-section {
  margin-top: 3rem;
  text-align: center;
}

.contact-section h3 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.contact-section p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  color: #555;
}

.contact-btn {
  display: inline-block;
  padding: 0.75rem 2rem;
  background-color: #007bff;
  color: white;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.contact-btn:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 20px rgba(0, 123, 255, 0.4);
}

/* Media Queries */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-intro-content {
    margin-right: 0;
    margin-bottom: 2rem;
    text-align: center;
    animation: slideInLeft 1s ease forwards; /* Keep the animation */
  }

  .about-skills {
    text-align: center;
    animation: slideInRight 1s ease forwards; /* Keep the animation */
  }

  .about-skills {
    padding: 1.5rem;
  }
}
