.projects-container {
  padding: 6rem 1rem;
  background-color: #ffffff;
  text-align: center;
}

/* Centered Heading with blue line on top-left */
.projects-container h1 {
  position: relative;
  font-size: 2.5rem;
  font-weight: 600;
  color: #333;
  display: inline-block; /* Allows ::before to align within the centered heading */
}

.projects-container h1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  width: 50px;
  height: 4px;
  background-color: #007bff;
  transform: translate(-60px, -10px); /* Adjust line position relative to centered heading */
}

/* Centered Intro text */
.projects-intro {
  margin-bottom: 3rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: #333;
  text-align: center;
}

/* Project grid */
.projects-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 3rem;
}

.project-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-img {
  width: 45%;
  height: auto;
  border-radius: 15px;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.project-item:hover .project-img {
  transform: scale(1.05);
}

.project-details {
  width: 50%;
  padding: 20px;
  text-align: left;
}

.project-details h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
}

.project-details p {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 300;
  color: #555;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .project-item {
    flex-direction: column;
    text-align: center;
  }
  
  .project-img,
  .project-details {
    width: 100%;
  }

  .project-details {
    padding: 15px 0;
  }
}