/* Navbar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4); 
  backdrop-filter: blur(10px);
  padding: 1rem 2rem;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
}

/* Logo section */
.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.navbar h2 {
  margin: 0;
  font-size: 1.5rem;
}

/* Navigation links */
.nav-links {
  display: flex; 
  gap: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Hover effect on links */
.nav-links a:hover {
  color: #fff;
  background-color: #4a90e2;
}

/* Active link styling */
.nav-links a.active {
  background-color: #4a90e2;
  color: white;
}

/* Hamburger menu */
.hamburger {
  display: none; /* Hide by default */
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
}

/* Mobile view - show hamburger and hide links */
@media (max-width: 768px) {
  .nav-links {
    display: none; 
    position: absolute;
    top: 60px;
    right: 0;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 1rem;
  }

  .nav-links.open {
    display: flex; 
  }

  .hamburger {
    display: flex;
  }
}