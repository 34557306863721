.contact-container {
  padding: 4rem 1rem;
  text-align: center;
  background-image: url("../../assets/contactBackground.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.contact-container h1 {
  color: white;
  position: relative;
  z-index: 2;
  margin-top: 15px;
}

.contact-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.contact-intro,
.contact-form {
  position: relative;
  z-index: 2;
}

.contact-intro {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  color: #fff;
  line-height: 1.6;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  z-index: 3;
  position: relative;
}

.form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

.form-group label {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}

.form-group textarea {
  resize: vertical;
  height: 160px;
}
.submit-btn {
  padding: 0.75rem 2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3);
}

.submit-btn:hover {
  background-color: #0056b3;
  box-shadow: 0 8px 16px rgba(0, 123, 255, 0.4);
}

.form-group input:hover,
.form-group textarea:hover {
  border-color: #0056b3;
}

.form-group input,
.form-group textarea {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .contact-form {
    padding: 2rem;
  }

  .submit-btn {
    width: 100%;
  }
}
