.education-container {
  padding: 5.5rem 2rem;
  text-align: center;
  background-color: #f3f4f6; /* Softer light gray background */
  font-family: "Roboto", sans-serif; /* Clean, modern font */
}

.education-container h1 {
  color: #2c3e50;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  display: inline-block;
  padding-bottom: 0.5rem;
}

.education-container h1::before {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  background-color: #007bff;
  margin-bottom: 1rem;
}

.education-details {
  max-width: 900px;
  margin: 0 auto;
}

.education-item {
  margin-bottom: 2.5rem;
  text-align: left;
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.education-item:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15); /* Enhanced hover shadow */
  transform: translateY(-5px);
}

.education-item h3 {
  margin-bottom: 0.75rem;
  color: #007bff; /* Accent blue color */
  font-size: 1.8rem;
  font-weight: 600;
}

.education-item p {
  margin: 0.4rem 0;
  color: #7f8c8d;
  font-size: 1.1rem;
}

.education-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8px;
  background-color: #007bff;
  border-radius: 0 5px 5px 0;
  transform: scaleY(0);
  transition: transform 0.3s ease;
}

.education-item:hover::before {
  transform: scaleY(1);
}

.education-item .duration {
  font-weight: bold;
  color: #34495e;
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .education-container {
    padding: 5rem 1rem;
  }

  .education-item {
    padding: 1.5rem;
  }

  .education-container h1 {
    font-size: 2.5rem;
  }

  .education-item h3 {
    font-size: 1.6rem;
  }

  .education-item p {
    font-size: 1rem;
  }
}
