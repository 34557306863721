/* Home container */
.home-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-image: url("../../assets/homeBackground.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #f0f0f0;
  position: relative;
  padding: 0 2rem;
}

/* Dark overlay */
.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Home content container */
.home-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  position: relative;
  z-index: 2;
}

/* Animation for image sliding in from the left */
.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
  opacity: 0; 
  transform: translateX(-100%);
  animation: slideInFromLeft 1.5s ease-out forwards; 
}

.home-image img {
  width: 80%;
  max-width: 300px;
  border-radius: 50%;
  object-fit: cover;
}

/* Animation for text sliding in from the right */
.home-text {
  flex: 1;
  padding: 0 2rem;
  opacity: 0;
  transform: translateX(100%);
  animation: slideInFromRight 1.5s ease-out forwards; 
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.profession {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.summary {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #f0f0f0;
  max-width: 600px;
}

/* Media query for responsive design */
@media (max-width: 768px) {
  .home-content {
    flex-direction: column;
    text-align: center;
  }

  .home-image,
  .home-text {
    width: 100%;
    padding: 0;
  }

  .home-image img {
    width: 60%;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  .profession {
    font-size: 1.3rem;
  }

  .summary {
    font-size: 1.1rem;
    padding: 0 1rem;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}